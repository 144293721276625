//import noUiSlider from 'nouislider';
import Flickity from 'flickity';
import Rellax from 'rellax';

var mobile = false;
var submitAttribute = false;
var doingSplash = false;
var flkty_c;
var playing;

document.addEventListener("DOMContentLoaded", function() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        mobile = true;
        document.body.classList.add('mobile');
    }
    pageLoaded();
    scrollFix();
    navScroller();
    videoPlayer();
    console.log("Loaded");

    parallax();
});
window.addEventListener('load',function() {
    setTimeout(function() { slider(); updatePosition(); },100);
    setTimeout(function() { updatePosition(); },50);
});



function parallax() {
    var rellax = new Rellax('.parallax');
    console.log(rellax);
}
function videoPlayer() {
    var yt =  document.querySelector("#vidPlayer");
    if(yt) { 
        var vid = document.querySelector("#vidPlayer video");
        vid.addEventListener('ended',function() {
            vid.autoplay=false;
            vid.load();
//            vid.currentTime = 0;
//            vid.pause();
            playing = false;
            document.body.classList.remove('video_playing');
        });
        yt.addEventListener('click',function(event) {
            if(playing) {
                playing = false;
                document.body.classList.remove('video_playing');
                document.querySelector("#vidPlayer video").pause();
               /* if(document.querySelector("#vidPlayer video").hasAttribute("controls")) {
                    document.querySelector("#vidPlayer video").removeAttribute("controls");
                } */
            } else {
                playing = true;
                document.body.classList.add('video_playing');
                document.querySelector("#vidPlayer video").play();
                //document.querySelector("#vidPlayer video").setAttribute("controls", "controls");
            }
            event.preventDefault();
        });
    }
}
function navScroller() {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if(currentScrollPos < 100) {
            document.body.classList.remove('scrolled');
        } else {
            if (prevScrollpos > currentScrollPos) {
                document.body.classList.remove('scrolled');
            } else {
                document.body.classList.add('scrolled');
            }
        }
        prevScrollpos = currentScrollPos;
    }
}
function updatePosition() {
    var hsh = window.location.hash;
    if(hsh != "") {
        var h = hsh.replace("#","");
        document.getElementById(h).scrollIntoView();
    }
}

function pageLoaded() {
    document.querySelector(".c-hurger__button").addEventListener('click',toggleNav);
    if(mobile) {
        var btns = document.querySelectorAll('.l-main nav a');
        for(var i=0;i<btns.length;i++) {
            btns[i].addEventListener('click',hideNav);
        }
    }
}
function toggleNav() {
    document.body.classList.toggle('is-open');
}
function hideNav() {
    document.body.classList.remove('is-open');
}

function slider() {
    var elem = document.querySelector('.flickity-container');
    var flkty = new Flickity( elem, {
        autoPlay: 0,
        bgLazyLoad: 2,
        cellAlign: "left",
        pauseAutoPlayOnHover: !1,
        prevNextButtons: !1,
        wrapAround: !0
    });

    var elem = document.querySelector('.c-slider');
    flkty_c = new Flickity( elem, {
        adaptiveHeight: !0,
        bgLazyLoad: 2,
        prevNextButtons: !1,
        wrapAround: !0
    });
    var nxt = document.querySelector('.c-slider__buttons .next');
    if(nxt) {
        nxt.addEventListener('click',function(event) { event.preventDefault(); flkty_c.next(true,false); });
    }
    var prv = document.querySelector('.c-slider__buttons .previous');
    if(prv) {
        prv.addEventListener('click',function(event) { event.preventDefault(); flkty_c.previous(true,false); });
    }
}

function nextClick() {
}

function scrollFix() {
    window.addEventListener("load", upScroll);
    window.addEventListener("scroll", upScroll);
    window.addEventListener("resize", upScroll);
}

function upScroll() {
    var n = document.querySelector(".l-about");
    var n_c = document.querySelector(".l-about__slider");
    var innerWidth = n.clientWidth;
    n_c.style.width = ((innerWidth/2))+"px";

    if (window.innerWidth < 968) {
        n_c.style.width = innerWidth+"px";
        n.classList.remove("is-fixed");
        n.classList.remove("beyond-fixed");
        return false;
    }
    var i = window.innerHeight,
        s = n.getBoundingClientRect(),
        e = s.top - 2 + 320,
        t = Math.round(s.bottom);
    if(e <= 0) {
        n.classList.add("is-fixed");
    } else {
        n.classList.remove("is-fixed");
    }
    if(t-i <= 0) {
        n.classList.add("beyond-fixed");
    } else {
        n.classList.remove("beyond-fixed");
    }
}